import { initializeConnector } from "@web3-react/core"
import { WalletConnect } from "@web3-react/walletconnect-v2"
import config from "config"

import { MAINNET_CHAINS, RPC_URLS } from "./chains"
import { Connection, ConnectionType, onError } from "./connection"

const [walletConnect, hooks] = initializeConnector<WalletConnect>(
  (actions) =>
    new WalletConnect({
      actions,
      options: {
        rpcMap: RPC_URLS,
        projectId: config.connector.walletConnectProjectId,
        chains: Object.keys(MAINNET_CHAINS).map(Number),
        showQrModal: false, // TODO
      },
      onError,
    })
)

export const walletConnectConnection: Connection = {
  connector: walletConnect,
  hooks: hooks,
  type: ConnectionType.WALLET_CONNECT,
}

