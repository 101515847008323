import React from "react";

import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

function WarningBanner() {
  const [open, setOpen] = React.useState(true);

  return (
    <React.Fragment>
      <Collapse in={open}>
        <Alert
          severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ "& .MuiAlert-icon": { marginLeft: "auto" } }}
        >
          Beta Version. Use at your own risk!
        </Alert>
      </Collapse>
    </React.Fragment>
  );
}

export default WarningBanner;
