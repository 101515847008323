import React from "react";

import { useSnackbar } from "notistack";
import { useSetAtom } from "jotai";

import axios from "axios";
import { loadProviderList, ProviderList } from "data/providerManifest";
import { providerManifestAtom } from "state/atoms";

function Preloader() {
  const { enqueueSnackbar } = useSnackbar();
  const setValue = useSetAtom(providerManifestAtom);

  // load data provider manifest
  React.useEffect(() => {
    let mounted = true;

    const load = async () => {
      try {
        const response = await axios({
          method: "get",
          baseURL: "https://raw.githubusercontent.com",
          url: "TowoLabs/ftso-signal-providers/next/bifrost-wallet.providerlist.json",
        });

        const manifest = loadProviderList(response.data as ProviderList);

        if (mounted) {
          setValue(manifest);
        }
      } catch (err) {
        console.error(err);
        if (mounted) {
          setValue(undefined);
        }
        if (axios.isAxiosError(err)) {
          enqueueSnackbar(`Failed to load provider manifest: ${err.message}`, { variant: "error" });
        } else {
          enqueueSnackbar("Failed to load provider manifest", { variant: "error" });
        }
      }
    };

    load();

    return () => {
      mounted = false;
    };
  }, [setValue]);

  return null;
}

export default Preloader;
