import { CoinbaseWallet } from "@web3-react/coinbase-wallet"
import { initializeConnector } from "@web3-react/core"

import { Connection, ConnectionType, onError } from "./connection"
import { SupportedChainId, RPC_URLS } from "./chains"
// import logo from "assets/images/logo_op.svg"

export function getIsCoinbaseWallet(): boolean {
  return window.ethereum?.isCoinbaseWallet ?? false
}

const [coinbaseWallet, hooks] = initializeConnector<CoinbaseWallet>(
  (actions) =>
    new CoinbaseWallet({
      actions,
      options: {
        url: RPC_URLS[SupportedChainId.FLARE][0],
        appName: "web3-react",
        // appLogoUrl: logo,
        reloadOnDisconnect: false,
      },
      onError,
    })
)

export const coinbaseWalletConnection: Connection = {
  connector: coinbaseWallet,
  hooks: hooks,
  type: ConnectionType.COINBASE_WALLET,
}
