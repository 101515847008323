import React from "react";

import { Box, useTheme } from "@mui/system";

import { createPulseAnimation } from "utils/animation";
import { WalletStatus } from "connectors";

const createIndicatorClass = (className: string, color: string) => {
  const { pulsingStyles } = createPulseAnimation(
    color,
    3,
    className
  );

    return {
      ...pulsingStyles,
      backgroundColor: color,
    };
};

type ConnectionIndicatorProps = {
  status: WalletStatus;
};

function ConnectionIndicator(props: ConnectionIndicatorProps) {
  const { status } = props;
  const theme = useTheme();

  const styles = React.useMemo(() => ({
    [WalletStatus.Connected]: createIndicatorClass("connected", theme.palette.success.main),
    [WalletStatus.Connecting]: createIndicatorClass("connecting", theme.palette.info.main),
    [WalletStatus.Disconnected]: createIndicatorClass("disconnected", theme.palette.error.main),
    [WalletStatus.WrongNetwork]: createIndicatorClass("wrongNetwork", theme.palette.warning.main),
  }), [theme]);
  
  return (
    <Box
      component="div"
      sx={{
        width: 8,
        height: 8,
        borderRadius: 4,
        ...styles[status],
      }}
    />
  );

}

export default ConnectionIndicator;
