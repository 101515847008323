import React from "react";
import { NavLink } from "react-router-dom";

import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { List as MuiList } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";

import logoText from "assets/images/logo_text_op.svg";
import NetworkStatus from "components/NetworkStatus";
import Settings from "components/Settings";
import WarningBanner from "components/WarningBanner";
import Web3Status from "components/Web3Status";

const StyledList = styled(MuiList)<{ component?: React.ElementType }>(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    padding: 0,
    "& .MuiListItem-root": {
      padding: 0,
    },
    "& .MuiListItemText-root": {
      margin: 0,
    },
    "& .MuiListItemButton-root": {
      padding: "6px 12px",
      "&:hover": {
        color: theme.palette.primary[400],
      },
      "&.active": {
        color: theme.palette.primary.dark,
      },
    },
  })
) as typeof MuiList;

const entries = [
  // ["Home", "/"],
  ["Flaredrop", "/flaredrop"],
  ["Epoch", "/epoch"],
  ["Wrap", "/wrap"],
  // ["Delegate", "/delegate"],
  // ["Rewards", "/rewards"],
  // ["Claim", "/claim"],
  ["FAQ", "/faq"],
];

function Header() {
  const theme = useTheme();

  return (
    <AppBar
      sx={{
        bgcolor: theme.palette.secondary[200],
        borderBottom: `1px solid ${theme.palette.secondary.light}`,
        boxShadow: "none",
      }}
      color="inherit"
      position="fixed"
    >
      <WarningBanner />
      <Container maxWidth="lg">
        <Toolbar component="div" disableGutters>
          <Box
            component="img"
            sx={{
              height: "28px",
              paddingRight: "24px",
            }}
            alt="FT Logo"
            src={logoText}
          />
          <Box sx={{ marginRight: "auto" }}>
            <StyledList component="nav">
              {entries.map(([name, to], i) => (
                <ListItem key={i}>
                  <ListItemButton component={NavLink} to={to}>
                    <ListItemText primary={name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </StyledList>
          </Box>
          <Stack direction="row" spacing={2}>
            <NetworkStatus />
            <Web3Status />
            <Settings />
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
