import React from "react";

import type { ManifestEntry } from "data/providerManifest";

export enum DialogIdentifier {
  DIALOG_PROVIDER_SELECT,
}

export type DialogDataProviderSelect = {
  index: number;
  // data: ManifestEntry;
  excludeAddress?: string;
};

export type DialogData = DialogDataProviderSelect | any; // TODO

export type DialogDataContextType = {
  activeDialog?: DialogIdentifier;
  data?: DialogData;
};

export type DialogApiContextType = {
  open: (id: DialogIdentifier, data?: DialogData) => void;
  close: () => void;
};

const initialStateData: DialogDataContextType = {
  activeDialog: undefined,
  data: undefined,
};

const initialStateApi: DialogApiContextType = {
  open: () => {
    return;
  },
  close: () => {
    return;
  },
};

export const DialogDataContext =
  React.createContext<DialogDataContextType>(initialStateData);

export const DialogApiContext =
  React.createContext<DialogApiContextType>(initialStateApi);

type DialogProviderProps = {
  children: React.ReactNode;
};

export function DialogProvider({ children }: DialogProviderProps) {
  const [activeDialog, setActiveDialog] = React.useState<DialogIdentifier>();
  const [data, setData] = React.useState<DialogData>();

  const open = React.useCallback((id: DialogIdentifier, data?: DialogData) => {
    setData(data);
    setActiveDialog(id);
  }, []);

  const close = React.useCallback(() => {
    setData(initialStateData.data);
    setActiveDialog(initialStateData.activeDialog);
  }, []);

  // Note: dictionary would change on each render
  const value = React.useMemo(
    () => ({
      open,
      close,
    }),
    [open, close]
  );

  return (
    <DialogDataContext.Provider
      value={{
        activeDialog,
        data,
      }}
    >
      <DialogApiContext.Provider value={value}>
        {children}
      </DialogApiContext.Provider>
    </DialogDataContext.Provider>
  );
}

export function useDialog(): DialogDataContextType & DialogApiContextType {
  const data = useDialogData();
  const api = useDialogApi();
  return { ...data, ...api };
}

export function useDialogData(): DialogDataContextType {
  const context = React.useContext(DialogDataContext);
  if (!context) {
    throw Error(
      "useDialogData can only be used within the DialogProvider component"
    );
  }
  return context;
}

export function useDialogApi(): DialogApiContextType {
  const context = React.useContext(DialogApiContext);
  if (!context) {
    throw Error(
      "useDialogApi can only be used within the DialogProvider component"
    );
  }
  return context;
}
