import { Link, Outlet } from "react-router-dom";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import Footer from "components/Footer";
import Header from "components/Header";
import ScrollTop from "components/ScrollTop";
import Polling from "components/Polling";
import TransactionStatusDialog from "components/TransactionStatusDialog";

import nexoImage from "assets/ads/nexo_01.png";

function MainLayout(props: any) {
  return (
    <Box>
      <Header />
      <Box
        component="main"
        id="anchor-back-to-top"
        sx={{ position: "relative", minHeight: "100vh" }}
      >
        <Container
          sx={{
            paddingBottom: "5.0rem", // footer height
            paddingTop: "9.375rem",
            display: "flex",
            justifyContent: "center",
          }}
          maxWidth="md"
        >
          <Box
            sx={{
              position: "absolute",
              top: "9.375rem",
              left: "calc(50% + 450px + 125px)", // 0.5 * (container + image) width
              transform: "translate(-50%, 0%)",
            }}
          >
            <Typography
              sx={{
                position: "absolute",
                top: "0px",
                left: "5px",
              }}
              variant="caption"
              color="#D3D3D3"
            >
              Ad
            </Typography>
            <Link to="links/nexo">
              <img src={nexoImage} alt="" width="250px" height="auto" />
            </Link>
          </Box>
          <Outlet />
        </Container>
        <Footer />
      </Box>
      <Polling />
      <TransactionStatusDialog />
      <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </Box>
  );
}

export default MainLayout;
