import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

import { CHAINS, SupportedChainId } from "connectors/chains";
import { FlareIcon, WFlareIcon } from "assets/images/icons";
import { SongbirdIcon, WSongbirdIcon } from "assets/images/icons";

export type NetworkInfo = {
  name: string;
  symbol: string;
  icon: React.ReactNode;
  iconWrapped: React.ReactNode;
};

export function getNetworkInfo(chainId: number | undefined): NetworkInfo {
  switch (chainId) {
    case SupportedChainId.FLARE:
      return {
        name: CHAINS[chainId].name,
        symbol: CHAINS[chainId].nativeCurrency.symbol,
        icon: <FlareIcon />,
        iconWrapped: <WFlareIcon />,
      };
    case SupportedChainId.SONGBIRD:
      return {
        name: CHAINS[chainId].name,
        symbol: CHAINS[chainId].nativeCurrency.symbol,
        icon: <SongbirdIcon />,
        iconWrapped: <WSongbirdIcon />,
      };
    default:
      return {
        name: "Unknown",
        symbol: "UNK",
        icon: <QuestionMarkIcon htmlColor="#e62058" />,
        iconWrapped: <QuestionMarkIcon htmlColor="#000000" />,
      };
  }
}
