import type { AddEthereumChainParameter } from "@web3-react/types";

import config from "config";

export const MIN_CONFIRMATIONS = 1;

export enum SupportedChainId {
  FLARE = 14,
  SONGBIRD = 19,
}

export function isSupportedChain(chainId: number | null | undefined): chainId is SupportedChainId {
  return !!chainId && !!SupportedChainId[chainId];
}

const FLR: AddEthereumChainParameter["nativeCurrency"] = {
  name: "Flare",
  symbol: "FLR",
  decimals: 18,
};

const SGB: AddEthereumChainParameter["nativeCurrency"] = {
  name: "Songbird",
  symbol: "SGB",
  decimals: 18,
};

interface BasicChainInformation {
  urls: string[];
  name: string;
}

interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter["nativeCurrency"];
  blockExplorerUrls: AddEthereumChainParameter["blockExplorerUrls"];
  addressContractRegistry: string;
  addressExecutor: string;
}

type ChainConfig = { [chainId: number]: ExtendedChainInformation };

export const MAINNET_CHAINS: ChainConfig = {
  [SupportedChainId.FLARE]: {
    urls: ["https://flare-api.flare.network/ext/C/rpc"],
    name: "Flare",
    nativeCurrency: FLR,
    blockExplorerUrls: ["https://flare-explorer.flare.network"],
    addressContractRegistry: "0xaD67FE66660Fb8dFE9d6b1b4240d8650e30F6019",
    addressExecutor: config.executor.addressFlare,
  },
};

export const TESTNET_CHAINS: ChainConfig = {
  [SupportedChainId.SONGBIRD]: {
    urls: ["https://songbird-api.flare.network/ext/C/rpc"],
    name: "Songbird",
    nativeCurrency: SGB,
    blockExplorerUrls: ["https://songbird-explorer.flare.network"],
    addressContractRegistry: "0xaD67FE66660Fb8dFE9d6b1b4240d8650e30F6019",
    addressExecutor: config.executor.addressSongbird,
  },
};

export const CHAINS: ChainConfig = {
  ...MAINNET_CHAINS,
  ...TESTNET_CHAINS,
};

export const RPC_URLS: { [chainId: number]: string[] } = Object.keys(CHAINS).reduce<{
  [chainId: number]: string[];
}>((accumulator, chainId) => {
  const validURLs: string[] = CHAINS[Number(chainId)].urls;

  if (validURLs.length) {
    accumulator[Number(chainId)] = validURLs;
  }

  return accumulator;
}, {});

const EXPLORER_PATHS = {
  [SupportedChainId.FLARE]: {
    tx: "tx",
    token: "token",
    address: "address",
    block: "block",
  },
  [SupportedChainId.SONGBIRD]: {
    tx: "tx",
    token: "token",
    address: "address",
    block: "block",
  },
};

export function getExplorerLink(
  chainId: SupportedChainId,
  data: string,
  type: "tx" | "token" | "address" | "block"
): string {
  const prefix = CHAINS[chainId].blockExplorerUrls?.[0];

  switch (type) {
    case "tx": {
      return `${prefix}/${EXPLORER_PATHS[chainId].tx}/${data}`;
    }
    case "token": {
      return `${prefix}/${EXPLORER_PATHS[chainId].token}/${data}`;
    }
    case "block": {
      return `${prefix}/${EXPLORER_PATHS[chainId].block}/${data}`;
    }
    case "address":
    default: {
      return `${prefix}/${EXPLORER_PATHS[chainId].address}/${data}`;
    }
  }
}
