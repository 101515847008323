import React from "react"

import { ConnectionType } from "connectors/connection"
import { getConnection } from "connectors"

const SELECTABLE_WALLETS = [ConnectionType.INJECTED, ConnectionType.COINBASE_WALLET, ConnectionType.WALLET_CONNECT]

export default function useOrderedConnections() {
  return React.useMemo(() => {
    const orderedConnectionTypes: ConnectionType[] = [];

    // Always attempt to use to Gnosis Safe first, as we can"t know if we"re in a SafeContext.
    orderedConnectionTypes.push(ConnectionType.GNOSIS_SAFE);

    // TODO add the `selectedWallet` to the top
    orderedConnectionTypes.push(...SELECTABLE_WALLETS);

    // Add network connection last as it should be the fallback.
    orderedConnectionTypes.push(ConnectionType.NETWORK);

    return orderedConnectionTypes.map(getConnection);
  }, [])
}
