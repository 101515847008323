import React from "react";
import { useWeb3React } from "@web3-react/core";

import Link from "@mui/material/Link";
import { styled, keyframes } from "@mui/system";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

import useBlockNumber from "hooks/useBlockNumber";
import { getExplorerLink, isSupportedChain } from "connectors/chains";
import config from "config";

const StyledPollingContainer = styled("div")(({ theme }) => ({
  position: "fixed",
  display: "flex",
  alignItems: "center",
  right: 0,
  bottom: 0,
  padding: "1.0rem 1.0rem",
}));

const StyledPolling = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: theme.palette.success.dark,
}));

const StyledPollingDot = styled("div")(({ theme }) => ({
  width: "8px",
  height: "8px",
  minHeight: "8px",
  minWidth: "8px",
  marginLeft: "0.5rem",
  borderRadius: "50%",
  position: "relative",
  backgroundColor: theme.palette.success.main,
}));

const rotate360 = keyframes({
  from: {
    transform: "rotate(0deg)",
  },
  to: {
    transform: "rotate(360deg)",
  },
});

const Spinner = styled("div")(({ theme }) => ({
  animation: `${rotate360} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite`,
  transform: "translateZ(0)",
  borderTop: "1px solid transparent",
  borderRight: "1px solid transparent",
  borderBottom: "1px solid transparent",
  borderLeft: `2px solid ${theme.palette.success.main}`,
  background: "transparent",
  width: "14px",
  height: "14px",
  borderRadius: "50%",
  position: "relative",
  left: "-3px",
  top: "-3px",
}));

export default function Polling() {
  const { chainId } = useWeb3React();
  const blockNumber = useBlockNumber();
  const [isMounted, setIsMounted] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!blockNumber) {
      return;
    }

    setIsMounted(true);
    const timer = setTimeout(() => setIsMounted(false), 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [blockNumber]);

  return (
    <StyledPollingContainer>
      <Typography
        sx={{ padding: "0px 12px", color: "secondary.main" }}
        fontSize={7}
        variant="body1"
      >
        v{config.app.version}
      </Typography>
      <Link
        href={
          chainId && isSupportedChain(chainId) && blockNumber
            ? getExplorerLink(chainId, blockNumber.toString(), "block")
            : ""
        }
        target="_blank"
        rel="noopener noreferrer"
        underline="none"
      >
        <StyledPolling>
          <Tooltip
            placement="top-end"
            title="The most recent block number on the network."
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            disableFocusListener
            arrow
          >
            <Typography
              sx={{
                opacity: isMounted ? "0.7" : "1.0",
                transition: "opacity 0.5s ease",
                "&:hover": {
                  opacity: "1.0",
                },
              }}
              fontWeight={500}
              fontSize={11}
            >
              {blockNumber}
            </Typography>
          </Tooltip>
          <StyledPollingDot>{!isMounted && <Spinner />}</StyledPollingDot>
        </StyledPolling>
      </Link>
    </StyledPollingContainer>
  );
}
