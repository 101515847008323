import React from "react";

import { useWeb3React } from "@web3-react/core";
import { ProviderRpcError } from '@web3-react/types'
import { GnosisSafe } from '@web3-react/gnosis-safe'
import { Network } from '@web3-react/network'
import { WalletConnect } from '@web3-react/walletconnect-v2'

import { useSnackbar } from "notistack";

import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Menu as MuiMenu, MenuProps } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { SupportedChainId } from "connectors/chains"; 
import { FlareIcon } from "assets/images/icons";
import { SongbirdIcon } from "assets/images/icons";
import { NetworkInfo, getNetworkInfo } from "connectors/networkInfo"

const Menu = styled((props: MenuProps) => (
  <MuiMenu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

// https://mui.com/material-ui/react-menu/#customization
// TODO make more dynamic by using CHAINS
function NetworkStatus() {
  // const [desiredChainId, setDesiredChainId] = React.useState<number>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { chainId, account, accounts, connector, provider, isActive, isActivating, hooks } = useWeb3React();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [ network, setNetwork ] = React.useState<NetworkInfo>(getNetworkInfo(chainId));

  // /**
  //  * When user connects eagerly (`desiredChainId` is undefined) or to the default chain (`desiredChainId` is -1),
  //  */
  // React.useEffect(() => {
  //   if (chainId && (!desiredChainId || desiredChainId === -1)) {
  //     setDesiredChainId(chainId);
  //   }
  // }, [chainId, desiredChainId])

  React.useEffect(() => {
    setNetwork(getNetworkInfo(chainId));
  }, [chainId]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setError = (err) => {

  };

  const handleAction = async (newChainId: number) => {
    setAnchorEl(null);
    // setDesiredChainId(newChainId);
    if (chainId !== newChainId) {
      // TODO catch errors
      // attempt to switch networks
      // connector.activate();
      try {
        // if (
        //   // If we're already connected to the desired chain, return
        //   desiredChainId === activeChainId ||
        //   // If they want to connect to the default chain and we're already connected, return
        //   (desiredChainId === -1 && activeChainId !== undefined)
        // ) {
        //   setError(undefined)
        //   return
        // }

        if (newChainId === -1 || connector instanceof GnosisSafe) {
          await connector.activate();
        } else if (
          connector instanceof WalletConnect ||
          connector instanceof Network
        ) {
          await connector.activate(newChainId);
        } else {
          // TODO getAddChainParameters(newChainId)
          await connector.activate(newChainId);
        }

        setError(undefined);
      } catch (error) {
        console.debug(`web3-react switch connection error: ${error}`);
        enqueueSnackbar(`Failed to switch network: ${(error as ProviderRpcError).message}`, { variant: "error" });
      }

    }
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={network.icon}
        disabled={!Boolean(chainId)}
        endIcon={<KeyboardArrowDownIcon />}
        color="secondary"
      >
        {network.name}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleAction(SupportedChainId.FLARE)}>
          <FlareIcon />
          Flare
        </MenuItem>
        <MenuItem onClick={() => handleAction(SupportedChainId.SONGBIRD)}>
          <SongbirdIcon />
          Songbird
        </MenuItem>
      </Menu>
    </React.Fragment>

  );
}

export default NetworkStatus;
