type Config = {
  app: {
    name: string;
    version: string;
  };
  connector: {
    walletConnectProjectId: string;
  };
  debug: boolean;
  executor: {
    addressFlare: string;
    addressSongbird: string;
  };
  gtag: string;
  socials: {
    discord: string;
    github: string;
    githubSupport: string;
    twitter: string;
    email: string;
  };
  affiliates: {
    nexo: string;
  };
};

const DEFAULT: Config = {
  app: {
    // name: process.env.REACT_APP_NAME as string,
    name: "flaretools",
    // version: process.env.REACT_APP_VERSION as string,
    version: "0.4.3",
  },
  connector: {
    walletConnectProjectId: "2e76523049a19d0c0f572505f5d7c003",
  },
  debug: true,
  executor: {
    // TODO replace with our executor
    addressFlare: "0xdD76d702358936Af39c0E9F7E71F9512C83cacaE",
    addressSongbird: "0x380a9bFf3fE29c75530943C6686c24b512Ee7356",
  },
  gtag: "G-61YRBFNFRM",
  socials: {
    discord: "https://discord.gg/CP6q2MS2xD",
    github: "https://github.com/rikublock/flaretools-help",
    githubSupport: "https://github.com/rikublock/flaretools-help/issues/new/choose",
    twitter: "https://twitter.com/flaretoolsnet",
    email: "flaretools@proton.me",
  },
  affiliates: {
    nexo: "https://nexo.sjv.io/R5X6Kv",
  },
};

const CI = {};

const config: Config = DEFAULT;

export default config;
