import { Web3ReactHooks } from "@web3-react/core"
import { Connector } from "@web3-react/types"

export function onError(error: Error) {
  console.debug(`web3-react error: ${error}`)
}

export enum ConnectionType {
  EMPTY = "EMPTY",
  INJECTED = "INJECTED",
  COINBASE_WALLET = "COINBASE_WALLET",
  WALLET_CONNECT = "WALLET_CONNECT",
  NETWORK = "NETWORK",
  GNOSIS_SAFE = "GNOSIS_SAFE",
}

export interface Connection {
  connector: Connector
  hooks: Web3ReactHooks
  type: ConnectionType
}
