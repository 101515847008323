import { isAddress } from "./address";

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export const shortenAddress = (address?: string, chars = 4): string => {
  if (!address) {
    return "";
  }

  if (address.length <= 2 * chars) {
    return address;
  }

  const parsed = isAddress(address);
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  const start = parsed.slice(0, chars);
  const end = parsed.slice(-chars);
  return `${start}...${end}`;
};

