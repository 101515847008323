export type TransactionStatusData = {
  type: TransactionStatus;
  data?: string;
};

export enum TransactionStatus {
  UNKNOWN = "UNKNOWN",
  WAIT_APPROVE = "WAIT_APPROVE",
  WAIT_CONFIRMATION = "WAIT_CONFIRMATION",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}
