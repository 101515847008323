import { initializeConnector } from "@web3-react/core";
import { Network } from "@web3-react/network";

import { RPC_URLS, SupportedChainId } from "./chains";
import { Connection, ConnectionType } from "./connection";

const [network, hooks] = initializeConnector<Network>(
  (actions) =>
    new Network({
      actions,
      urlMap: RPC_URLS,
      defaultChainId: SupportedChainId.FLARE,
    })
);

export const networkConnection: Connection = {
  connector: network,
  hooks: hooks,
  type: ConnectionType.NETWORK,
};
