import { initializeConnector } from "@web3-react/core"
import { MetaMask } from "@web3-react/metamask"

import { Connection, ConnectionType, onError } from "./connection"

export function getIsInjected(): boolean {
  return Boolean(window.ethereum)
}

export function getIsBraveWallet(): boolean {
  return window.ethereum?.isBraveWallet ?? false
}

export function getIsMetaMaskWallet(): boolean {
  // When using Brave browser, `isMetaMask` is set to true when using the built-in wallet
  // This function should return true only when using the MetaMask extension
  // https://wallet-docs.brave.com/ethereum/wallet-detection#compatability-with-metamask
  return (window.ethereum?.isMetaMask ?? false) && !getIsBraveWallet()
}

const [metaMask, hooks] = initializeConnector<MetaMask>((actions) => new MetaMask({ actions, onError }))

export const injectedConnection: Connection = {
  connector: metaMask,
  hooks: hooks,
  type: ConnectionType.INJECTED,
}
