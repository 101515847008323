import { Theme } from "@mui/material/styles";

export default function Menu(theme: Theme) {
  return {
    MuiMenu: {
      defaultProps: {
        elevation: 0,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      },
      styleOverrides: {
        root: {
          color: "red",
        },
        paper: {
          borderRadius: 7,
          marginTop: theme.spacing(1),
          minWidth: 180,
          color: "red",
          // color:
          //   theme.palette.mode === "light"
          //     ? "rgb(55, 65, 81)"
          //     : theme.palette.grey[300],
          boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        },
        list: {
          padding: "4px 0",
        },
      },
    },
  };
}
