import { initializeConnector } from "@web3-react/core"
import { GnosisSafe } from "@web3-react/gnosis-safe"

import { Connection, ConnectionType } from "./connection"

const [gnosisSafe, hooks] = initializeConnector<GnosisSafe>((actions) => new GnosisSafe({ actions }))

export const gnosisSafeConnection: Connection = {
  connector: gnosisSafe,
  hooks: hooks,
  type: ConnectionType.GNOSIS_SAFE,
}
