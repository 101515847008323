import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import { Link } from "@mui/material";
import config from "config";

function Footer() {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      id="footer"
      sx={{
        bottom: "0px",
        width: "100%",
        minHeight: "4.0rem",
        position: "absolute",
        padding: "16px 0px 28px 0px",
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
        maxWidth="md"
      >
        <Typography sx={{ marginRight: "auto", color: "secondary.main" }} variant="body1">
          Copyright © {new Date().getFullYear()} Flare Tools. All rights reserved.
        </Typography>
        <Stack direction="row" spacing={2}>
          <Link
            href={config.socials.discord}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              "&:hover .fa-flag": {
                color: theme.palette.primary.light,
              },
            }}
          >
            <FontAwesomeIcon
              className="fa-flag"
              icon={faDiscord}
              color={theme.palette.secondary.main}
              size="xl"
            />
          </Link>
          <Link
            href={config.socials.github}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              "&:hover .fa-flag": {
                color: theme.palette.primary.light,
              },
            }}
          >
            <FontAwesomeIcon
              className="fa-flag"
              icon={faGithub}
              color={theme.palette.secondary.main}
              size="xl"
            />
          </Link>
          <Link
            href={config.socials.twitter}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              "&:hover .fa-flag": {
                color: theme.palette.primary.light,
              },
            }}
          >
            <FontAwesomeIcon
              className="fa-flag"
              icon={faTwitter}
              color={theme.palette.secondary.main}
              size="xl"
            />
          </Link>
          <Link
            href={`mailto:${config.socials.email}`}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              "&:hover .fa-flag": {
                color: theme.palette.primary.light,
              },
            }}
          >
            <FontAwesomeIcon
              className="fa-flag"
              icon={faEnvelope}
              color={theme.palette.secondary.main}
              size="xl"
            />
          </Link>
        </Stack>
      </Container>
    </Box>
  );
}

export default Footer;
