import React from "react";

import {
  Web3ReactHooks,
  Web3ReactProvider,
} from "@web3-react/core";
import { Connector } from "@web3-react/types";
import { Connection } from "connectors/connection";
import { getConnectionName } from "connectors";
import useEagerlyConnect from "hooks/useEagerlyConnect";
import useOrderedConnections from "hooks/useOrderedConnections";

interface Props {
  children: React.ReactNode;
}

function Web3Provider(props: Props) {
  const { children } = props;

  useEagerlyConnect();
  const connections = useOrderedConnections();
  const connectors: [Connector, Web3ReactHooks][] = connections.map(
    ({ hooks, connector }) => [connector, hooks]
  );

  const key = React.useMemo(
    () =>
      connections
        .map(({ type }: Connection) => getConnectionName(type))
        .join("-"),
    [connections]
  );

  return (
    <Web3ReactProvider connectors={connectors} key={key} lookupENS={false}>
      {children}
    </Web3ReactProvider>
  );
}

export default Web3Provider;
