import SvgIcon from "@mui/material/SvgIcon";

export function FlareIcon(props){
  return (
    <SvgIcon fontSize="24px" viewBox="0 0 38 38" htmlColor="#e62058" {...props}>
       <g>
        <path d="m27.925 14.37h-18.1c-4.97-0.02-9.04 3.93-9.17 8.9 0 0.13 0.1 0.24 0.24 0.24h18.1c4.97 0.02 9.04-3.94 9.17-8.91 0-0.13-0.11-0.24-0.24-0.24z"/>
        <path d="m37.095 0.63h-27.27c-4.97-0.02-9.04 3.94-9.16 8.91 0 0.13 0.1 0.24 0.23 0.24h27.28c4.97 0.02 9.04-3.94 9.17-8.91 0-0.13-0.11-0.24-0.24-0.24z"/>
        <circle cx="5.245" cy="32.62" r="4.59"/>
      </g>
    </SvgIcon>
  );
}

export function SongbirdIcon(props) {
  return (
    <SvgIcon fontSize="24px" viewBox="0 0 38 38" htmlColor="#e62058" {...props}>
      <g transform="matrix(.23641 0 0 .23641 6.6145 .057173)">
        <path d="m48.41 119.13-46.41 82.961 37.971-11.311z"/>
        <path d="m52.543 112.82-5.7422 49.348 67.252-41.418c-20.503-2.6475-41.006-5.2874-61.51-7.9297z"/>
        <path d="m137.86 71.242-7.5996 21.545 15.012 13.588z"/>
        <path d="m135.31 68.361-31.137 15.299 14.6 31.572z"/>
        <path d="m91.043 63.277c-12.261 15.463-24.534 30.918-36.803 46.375l46.977 6.0547 14.965 1.9297-15.748-34.053z"/>
        <path d="m16.965 1 34.734 106.45c12.464-15.699 24.927-31.399 37.389-47.1z"/>
      </g>
  </SvgIcon>
  );
}

export function WFlareIcon(props) {
  return (
    <FlareIcon htmlColor="#000000" />
  );
}

export function WSongbirdIcon(props) {
  return (
    <SongbirdIcon htmlColor="#000000" />
  );
}
