import { atom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";

import { Manifest } from "data/providerManifest";
import { ConnectionType } from "connectors/connection";
import { TransactionStatus, TransactionStatusData } from "connectors/transactionStatus";

const storage = createJSONStorage(() => sessionStorage);
export const selectedWalletAtom = atomWithStorage("selected-wallet", ConnectionType.EMPTY, storage);
// export const transactionStatusAtom = atomWithStorage("tx-status", TransactionStatus.UNKNOWN, storage);

export const providerManifestAtom = atom<Manifest | undefined>({});
export const transactionStatusAtom = atom<TransactionStatusData>({
  type: TransactionStatus.UNKNOWN,
});
