import React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import config from "config";

const ClaimPage = Loadable(React.lazy(() => import("pages/ClaimPage")));
const DebugPage = Loadable(React.lazy(() => import("pages/DebugPage")));
const DelegatePage = Loadable(React.lazy(() => import("pages/DelegatePage")));
const EpochPage = Loadable(React.lazy(() => import("pages/EpochPage")));
const ErrorPage = Loadable(React.lazy(() => import("pages/ErrorPage")));
const FAQPage = Loadable(React.lazy(() => import("pages/FAQPage")));
const FlaredropPage = Loadable(React.lazy(() => import("pages/FlaredropPage")));
// const HomePage = Loadable(React.lazy(() => import("pages/HomePage")));
const RewardPage = Loadable(React.lazy(() => import("pages/RewardPage")));
const WrapPage = Loadable(React.lazy(() => import("pages/WrapPage")));

function Affiliate() {
  const { id } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (id === "nexo") {
      window.location.replace(config.affiliates.nexo);
    }
  }, [id]);

  return null;
}

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  errorElement: <ErrorPage />,
  children: [
    {
      path: "/",
      // element: <HomePage />,
      element: <Navigate to="/flaredrop" replace />,
    },
    {
      path: "/flaredrop",
      element: <FlaredropPage title="Flaredrop Details | FlareTools" />,
    },
    {
      path: "/epoch",
      element: <EpochPage title="Epoch Details | FlareTools" />,
    },
    {
      path: "/wrap",
      element: <WrapPage title="Wrap & Unwrap Coins | FlareTools" />,
    },
    // {
    //   path: "/delegate",
    //   element: <DelegatePage title="Delegate Tokens to FTSO | FlareTools" />,
    // },
    // {
    //   path: "/rewards",
    //   element: <RewardPage title="Claim Rewards | FlareTools" />,
    // },
    // {
    //   path: "/claim",
    //   element: <ClaimPage />,
    // },
    {
      path: "/faq",
      element: <FAQPage title="Frequently Asked Questions | FlareTools" />,
    },
    {
      path: "/debug",
      element: <DebugPage title="Debug | FlareTools" />,
    },
    {
      path: "/links/:id",
      element: <Affiliate />,
    },
  ],
};

export default MainRoutes;
