import React from "react";
import { useSetAtom } from "jotai";
import { Button, Box, Menu, MenuItem } from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import { ProviderRpcError } from "@web3-react/types";
import { useSnackbar } from "notistack";

import { injectedConnection } from "connectors/metaMask";
import { isSupportedChain } from "connectors/chains";
import { shortenAddress } from "utils/strings";
import ConnectionIndicator from "components/ConnectionIndicator";
import { WalletStatus } from "connectors";
import { selectedWalletAtom } from "state/atoms";
import { ConnectionType } from "connectors/connection";

function Web3Status() {
  const { connector, chainId, account, accounts, provider, isActive, isActivating, hooks } = useWeb3React();
  const [ status, setStatus ] = React.useState<WalletStatus>(WalletStatus.Disconnected);
  const [ statusText, setStatusText ] = React.useState<string>("Connect Wallet");
  const setSelectedWallet = useSetAtom(selectedWalletAtom);
  const { enqueueSnackbar } = useSnackbar();

  // TODO we are mixing connectors injected vs currently active (seems okay as only support metamask currently)
  const connected = injectedConnection.hooks.useIsActive();

  const [walletMenuAnchor, setWalletMenuAnchor] = React.useState<null | HTMLElement>(null);

  React.useEffect(() => {
    if(connected) {
      if (isSupportedChain(chainId)) {
        setStatus(WalletStatus.Connected);
      } else {
        setStatus(WalletStatus.WrongNetwork);
      }
    } else {
      setStatus(WalletStatus.Disconnected);
    }
  }, [chainId, connected]);

  React.useEffect(() => {
    if(account) {
      setStatusText(shortenAddress(account));
    } else {
      setStatusText("Connect Wallet");
    }
  }, [account, isActive, isActivating]);

  const handleMenuClose = React.useCallback(() => {
    setWalletMenuAnchor(null);
  }, []);

  const handleClick = React.useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      if (connected) {
        setWalletMenuAnchor(event.currentTarget);
      } else {
        try {
          // TODO Add activate parameters here
          await injectedConnection.connector.activate();
          setSelectedWallet(ConnectionType.INJECTED);
        } catch(error) {
          enqueueSnackbar(`Failed to connect: ${(error as ProviderRpcError).message}`, { variant: "error" });
        }
      }
    },
    [connected, enqueueSnackbar, setSelectedWallet]
  );

  const handleAction = React.useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      setWalletMenuAnchor(null);
      try {
        if (injectedConnection.connector?.deactivate) {
          await injectedConnection.connector.deactivate();
        } else {
          await injectedConnection.connector.resetState();
        }
        setSelectedWallet(ConnectionType.EMPTY);
      } catch(error) {
        enqueueSnackbar(`Failed to disconnect: ${(error as ProviderRpcError).message}`, { variant: "error" });
      }
    },
    [enqueueSnackbar, setSelectedWallet]
  );

  return (
    <Box>
      <Button
        sx={{ whiteSpace: "nowrap" }}
        variant="contained"
        onClick={handleClick}
        startIcon={<ConnectionIndicator status={status} />}
        color="secondary"
        title="Web3 status"
      >
        {statusText}
      </Button>
      <Menu
        id="basic-menu"
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={walletMenuAnchor}
        open={Boolean(walletMenuAnchor)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleAction}>
          Disconnect
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default Web3Status;
