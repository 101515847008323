import { Connector } from "@web3-react/types"

import { ConnectionType } from "./connection"
import { coinbaseWalletConnection } from "./coinbaseWallet"
import { emptyConnection } from "./empty"
import { gnosisSafeConnection } from "./gnosisSafe"
import { injectedConnection, getIsMetaMaskWallet } from "./metaMask"
import { networkConnection } from "./network"
import { walletConnectConnection } from "./walletConnect"

export const CONNECTIONS = [
  gnosisSafeConnection,
  injectedConnection,
  coinbaseWalletConnection,
  walletConnectConnection,
  networkConnection,
]

export enum WalletStatus {
  Connected = "connected",
  Connecting = "connecting",
  Disconnected = "disconnected",
  WrongNetwork = "wrong_network",
}

export function getConnection(c: Connector | ConnectionType) {
  if (c instanceof Connector) {
    const connection = CONNECTIONS.find((connection) => connection.connector === c)
    if (!connection) {
      throw Error("unsupported connector")
    }
    return connection
  } else {
    switch (c) {
      case ConnectionType.EMPTY:
        return emptyConnection;
      case ConnectionType.INJECTED:
        return injectedConnection;
      case ConnectionType.COINBASE_WALLET:
        return coinbaseWalletConnection;
      case ConnectionType.WALLET_CONNECT:
        return walletConnectConnection;
      case ConnectionType.NETWORK:
        return networkConnection;
      case ConnectionType.GNOSIS_SAFE:
        return gnosisSafeConnection;
    }
  }
}

export function getConnectionName(
  connectionType: ConnectionType,
  hasMetaMaskExtension: boolean = getIsMetaMaskWallet()
): string {
  switch (connectionType) {
    case ConnectionType.EMPTY:
      return "Empty";
    case ConnectionType.INJECTED:
      return hasMetaMaskExtension ? "MetaMask" : "Browser Wallet";
    case ConnectionType.COINBASE_WALLET:
      return "Coinbase Wallet";
    case ConnectionType.WALLET_CONNECT:
      return "WalletConnect";
    case ConnectionType.NETWORK:
      return "Network";
    case ConnectionType.GNOSIS_SAFE:
      return "Gnosis Safe";
  }
}
