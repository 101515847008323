import React from "react";
import { useWeb3React } from "@web3-react/core";
import { useAtom } from "jotai";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";

import { getExplorerLink, isSupportedChain } from "connectors/chains";
import { transactionStatusAtom } from "state/atoms";
import { TransactionStatus } from "connectors/transactionStatus";
import { MIN_CONFIRMATIONS } from "connectors/chains";

function TransactionStatusDialog() {
  const { chainId } = useWeb3React();
  const [transactionStatus, setTransactionStatus] = useAtom(transactionStatusAtom);
  const [open, setOpen] = React.useState<boolean>(false);
  const [allowClose, setAllowClose] = React.useState<boolean>(false);

  React.useEffect(() => {
    switch (transactionStatus.type) {
      case TransactionStatus.WAIT_APPROVE:
        setAllowClose(false);
        setOpen(true);
        break;
      case TransactionStatus.WAIT_CONFIRMATION:
        setAllowClose(false);
        setOpen(true);
        break;
      case TransactionStatus.ERROR:
        setAllowClose(true);
        setOpen(true);
        break;
      case TransactionStatus.SUCCESS:
        setAllowClose(true);
        setOpen(true);
        break;
      default:
        setAllowClose(true);
        break;
    }
  }, [transactionStatus, setOpen]);

  const handleClose = (event, reason?: string) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpen(false);
  };

  const handleConfirm = () => {
    setTransactionStatus({ type: TransactionStatus.UNKNOWN });
    setOpen(false);
  };

  // TODO error message
  const statusContent = React.useMemo(() => {
    switch (transactionStatus.type) {
      case TransactionStatus.WAIT_APPROVE:
        return (
          <React.Fragment>
            <CircularProgress />
            <DialogContentText>
              Please check your wallet and approve or reject the transaction.
            </DialogContentText>
          </React.Fragment>
        );
      case TransactionStatus.WAIT_CONFIRMATION:
        return (
          <React.Fragment>
            <CircularProgress />
            <DialogContentText>
              Waiting for {MIN_CONFIRMATIONS} network confirmation(s)...
            </DialogContentText>
          </React.Fragment>
        );
      case TransactionStatus.ERROR:
        return (
          <React.Fragment>
            <DialogContentText>Error!</DialogContentText>
            {/* <DialogContentText>{status.data}</DialogContentText> */}
            <DialogContentText>Check logs for more details.</DialogContentText>
          </React.Fragment>
        );
      case TransactionStatus.SUCCESS:
        return (
          <React.Fragment>
            <DialogContentText>Success!</DialogContentText>
            <DialogContentText>
              View transaction on the{" "}
              {isSupportedChain(chainId) ? (
                <Link
                  href={getExplorerLink(chainId, transactionStatus.data!, "tx")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  explorer
                </Link>
              ) : (
                <React.Fragment>explorer</React.Fragment>
              )}
              .
            </DialogContentText>
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <DialogContentText>Unknown. Refresh the page.</DialogContentText>
          </React.Fragment>
        );
    }
  }, [transactionStatus, chainId]);

  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            maxWidth: "24rem",
          },
        },
      }}
      open={open}
      onClose={handleClose}
      fullWidth
      disableEscapeKeyDown={!allowClose}
    >
      <DialogTitle
        sx={{
          paddingBottom: 0,
        }}
        variant="h5"
      >
        Transaction Status
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
        size="small"
        disabled={!allowClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <DialogContent
        sx={{
          textAlign: "center",
        }}
      >
        {statusContent}
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {allowClose && <Button onClick={handleConfirm}>OK</Button>}
      </DialogActions>
    </Dialog>
  );
}

export default TransactionStatusDialog;
