import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

function Loader() {
  return (
    <Box sx={{ display: "block", padding: "1rem 0rem", textAlign: "center" }}>
      <CircularProgress color="primary" />
      <Typography color="secondary.dark" variant="body1">
        Loading
      </Typography>
    </Box>
  );
}

export default Loader;
