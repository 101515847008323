import { Theme } from "@mui/material/styles";

import Button from "./Button";
import Chip from "./Chip";
import IconButton from "./IconButton";
import Link from "./Link";
import ListItemIcon from "./ListItemIcon";
import Menu from "./Menu";
import Typography from "./Typography";

export default function ComponentsOverrides(theme: Theme) {
    return Object.assign(
        Button(theme),
        Chip(theme),
        IconButton(theme),
        Link(),
        ListItemIcon(),
        Menu(theme),
        Typography(),
    );
}
