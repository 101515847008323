import React from "react";
import { useAtom } from "jotai";
import { Connector } from "@web3-react/types";

import { gnosisSafeConnection } from "connectors/gnosisSafe";
import { networkConnection } from "connectors/network";

import { getConnection } from "connectors";
import { Connection, ConnectionType } from "connectors/connection";
import { selectedWalletAtom } from "state/atoms";

async function connect(connector: Connector) {
  try {
    if (connector.connectEagerly) {
      await connector.connectEagerly();
    } else {
      await connector.activate();
    }
  } catch (error) {
    console.debug(`web3-react eager connection error: ${error}`);
  }
}

export default function useEagerlyConnect() {
  const [selectedWallet, setSelectedWallet] = useAtom(selectedWalletAtom);

  let selectedConnection: Connection | undefined;
  if (selectedWallet) {
    try {
      selectedConnection = getConnection(selectedWallet as ConnectionType);
    } catch {
      setSelectedWallet(ConnectionType.EMPTY);
    }
  }

  React.useEffect(() => {
    connect(gnosisSafeConnection.connector);
    connect(networkConnection.connector);

    if (selectedConnection) {
      connect(selectedConnection.connector);
    }
  }, [selectedConnection]);
}
