import React from "react";
import { NavLink } from "react-router-dom";
import { Box, Menu, MenuItem, IconButton, Button, Link } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import config from "config";

function Settings() {
  const [walletMenuAnchor, setWalletMenuAnchor] = React.useState<null | HTMLElement>(null);

  const iconBackColor = "grey.100";
  const iconBackColorOpen = "grey.200";

  const handleMenuClose = React.useCallback(() => {
    setWalletMenuAnchor(null);
  }, []);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setWalletMenuAnchor(event.currentTarget);
    },
    []
  );

  const handleAction = React.useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      setWalletMenuAnchor(null);
      // TODO
    },
    []
  );

  // TODO
  // <IconButton
  //   onClick={handleClick}
  //   aria-label="open settings"
  //   color="secondary"
  //   title="Settings"
  // >
  //   <SettingsRoundedIcon sx={{ width: "20px"}} />
  // </IconButton>

  return (
    <Box>
      <Button
        variant="contained"
        onClick={handleClick}
        color="secondary"
        title="Settings"
      >
        <SettingsRoundedIcon sx={{ width: "1.25rem" }} />
      </Button>
      <Menu
        anchorEl={walletMenuAnchor}
        open={Boolean(walletMenuAnchor)}
        onClose={handleMenuClose}
      >
        <Box sx={{ padding: "6px 0px 6px 24px" }}>
          <FormControl variant="standard">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch disabled={true} defaultChecked={false} size="small" />
                }
                label="Dark Mode"
              />
            </FormGroup>
          </FormControl>
        </Box>
        <Link
          href={config.socials.githubSupport}
          target="_blank"
          rel="noopener noreferrer"
          underline="none"
        >
          <MenuItem onClick={handleAction}>Support</MenuItem>
        </Link>
        {config.debug && (
          <Link component={NavLink} to="/debug" underline="none">
            <MenuItem sx={{ textDecoration: "none" }} onClick={handleAction}>
              Debug
            </MenuItem>
          </Link>
        )}
      </Menu>
    </Box>
  );
}

export default Settings;
