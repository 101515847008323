import { initializeConnector } from "@web3-react/core"
import { EMPTY, Empty } from "@web3-react/empty"

import { Connection, ConnectionType } from "./connection"

const [empty, hooks] = initializeConnector<Empty>(() => EMPTY)

export const emptyConnection: Connection = {
  connector: empty,
  hooks: hooks,
  type: ConnectionType.EMPTY,
}

