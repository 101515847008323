import { useMemo } from "react";

import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Palette from "./palette";
import Typography from "./typography";
import componentsOverride from "./overrides";

interface Props {
  children: React.ReactNode;
}

function ThemeCustomization(props: Props) {
  const { children } = props;

  const theme = Palette("light");
  const themeTypography = Typography(`"Public Sans", sans-serif`);

  const themeOptions = useMemo(
    () => ({
      // breakpoints: {
      //   values: {
      //     xs: 0,
      //     sm: 768,
      //     md: 1024,
      //     lg: 1266,
      //     xl: 1536,
      //   },
      // },
      // mixins: {
      //   toolbar: {
      //     minHeight: 60,
      //     paddingTop: 8,
      //     paddingBottom: 8,
      //   },
      // },
      palette: theme.palette,
      typography: themeTypography,
    }),
    [theme, themeTypography]
  );

  const themes = createTheme(themeOptions);
  themes.components = componentsOverride(themes);

  return (
    <ThemeProvider theme={themes}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

export default ThemeCustomization;
