import React from "react";
import { create } from "zustand";

import { ProviderConfig } from "types";

type State = {
  loadedConfig0?: ProviderConfig;
  loadedConfig1?: ProviderConfig;
  config0?: ProviderConfig;
  config1?: ProviderConfig;
};

type Actions = {
  setLoadedConfig: (index: number, config?: ProviderConfig) => void;
  setConfig: (index: number, config?: ProviderConfig) => void;
  reset: () => void;
};

const initialState: State = {
  loadedConfig0: undefined,
  loadedConfig1: undefined,
  config0: undefined,
  config1: undefined,
};

const initialActions: Actions = {
  setLoadedConfig: (index: number, config?: ProviderConfig) => {
    return;
  },
  setConfig: (index: number, config?: ProviderConfig) => {
    return;
  },
  reset: () => {
    return;
  },
};

const useDelegationStore = create<State & Actions>()((set, get) => ({
  ...initialState,
  setLoadedConfig: (index: number, config?: ProviderConfig) => {
    if (index === 0) {
      set({ loadedConfig0: config });
    } else if (index === 1) {
      set({ loadedConfig1: config });
    } else {
      throw Error("Invalid index");
    }
  },
  setConfig: (index: number, config?: ProviderConfig) => {
    if (index === 0) {
      set({ config0: config });
    } else if (index === 1) {
      set({ config1: config });
    } else {
      throw Error("Invalid index");
    }
  },
  reset: () => {
    set(initialState);
  },
}));

export const DelegationDataContext = React.createContext<State>(initialState);

export const DelegationApiContext = React.createContext<Actions>(initialActions);

type DelegationProviderProps = {
  children: React.ReactNode;
};

export function DelegationProvider({ children }: DelegationProviderProps) {
  const state = useDelegationStore();

  // Note: dictionary would change on each render
  const value = React.useMemo(
    () => ({
      setLoadedConfig: state.setLoadedConfig,
      setConfig: state.setConfig,
      reset: state.reset,
    }),
    []
  );

  return (
    <DelegationDataContext.Provider
      value={{
        loadedConfig0: state.loadedConfig0,
        loadedConfig1: state.loadedConfig0,
        config0: state.config0,
        config1: state.config1,
      }}
    >
      <DelegationApiContext.Provider value={value}>{children}</DelegationApiContext.Provider>
    </DelegationDataContext.Provider>
  );
}

export function useDelegation(): State & Actions {
  const data = useDelegationData();
  const api = useDelegationApi();
  return { ...data, ...api };
}

export function useDelegationData(): State {
  const context = React.useContext(DelegationDataContext);
  if (!context) {
    throw Error("useDelegationData can only be used within the DelegationProvider component");
  }
  return context;
}

export function useDelegationApi(): Actions {
  const context = React.useContext(DelegationApiContext);
  if (!context) {
    throw Error("useDelegationApi can only be used within the DelegationProvider component");
  }
  return context;
}
