import React from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { Provider as JotaiProvider } from "jotai";
import { SnackbarProvider } from "notistack";

import Slide from "@mui/material/Slide";

import Routes from "routes";
import Web3Provider from "components/Web3Provider";
import ThemeCustomization from "themes";
import { BlockNumberProvider } from "hooks/useBlockNumber";
import Preloader from "components/Preloader";
import CookieConsent from "components/CookieConsent";
import { DialogProvider } from "contexts/DialogContext";
import { DelegationProvider } from "contexts/DelegationContext";

function App() {
  const location = useLocation();

  React.useEffect(() => {
    ReactGA.gtag("event", "page_view", {
      page_title: document.title,
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);

  return (
    <JotaiProvider>
      <Web3Provider>
        <DialogProvider>
          <ThemeCustomization>
            <SnackbarProvider
              autoHideDuration={10000}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              TransitionComponent={Slide}
              maxSnack={3}
            >
              <BlockNumberProvider>
                <DelegationProvider>
                  <Preloader />
                  <Routes />
                  {/* <CookieConsent /> */}
                </DelegationProvider>
              </BlockNumberProvider>
            </SnackbarProvider>
          </ThemeCustomization>
        </DialogProvider>
      </Web3Provider>
    </JotaiProvider>
  );
}

export default App;
