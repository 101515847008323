import { isSupportedChain, SupportedChainId } from "connectors/chains";
import { shortenAddress } from "utils/strings";

// structure of remote manifest file
type ProviderDetails = {
  chainId: number;
  name: string;
  description: string;
  url: string;
  address: string;
  logoURI: string;
  listed?: boolean;
};

export type ProviderList = {
  name: string;
  timestamp: string;
  version: {
    major: number;
    minor: number;
    patch: number;
  };
  providers: ProviderDetails[];
};

export type ProviderInfo = {
  address: string;
  name: string;
  logoURI: string;
};

export type DataProviderInfo = {
  name: string;
  logoURI: string | null;
};

export type ManifestEntry = {
  [key: string]: ProviderInfo;
};

export type Manifest = {
  [key in SupportedChainId]?: ManifestEntry;
};

// TODO make sure we deal with checksum addresses
export function getDataProviderInfo(
  address: string,
  manifest?: Manifest,
  chainId?: number
): DataProviderInfo {
  if (manifest && chainId && chainId in manifest) {
    if (address in manifest[chainId]) {
      return manifest[chainId][address];
    }
  }

  // default
  return {
    name: shortenAddress(address),
    logoURI: null,
  };
}

export function loadProviderList(data: ProviderList): Manifest {
  const manifest = Object.fromEntries(
    Object.values(SupportedChainId)
      .filter((x) => !Number.isNaN(Number(x)))
      .map((v) => [v, {}])
  );

  (data as ProviderList).providers.forEach((p) => {
    if (isSupportedChain(p.chainId) && p.listed) {
      manifest[p.chainId][p.address] = {
        address: p.address,
        name: p.name,
        logoURI: p.logoURI,
      };
    }
  });

  return manifest;
}
